<template>
  <b-card>
    <template #header>
      <h6 class="mb-0">Delete user</h6>
    </template>
    <b-card-text>
      <div class="content">
        <p>Completely remove user...</p>
        <b-button variant="danger" v-b-modal.modal-confirm-delete>Delete</b-button>
        <b-modal id="modal-confirm-delete" title="Delete user" @ok="confirmDelete()">
            <p class="my-4">Are you sure that you want to delete this user?</p>
        </b-modal>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  props: ['member'],
  components: {

  },
  data(){
      return {

      };
  },
  methods: {
    confirmDelete(){
      this.$api.delete(`user/${this.member.get('id')}`).then(() => {
        this.$router.push({name: "users.manager.browse"});
        this.$store.dispatch('Notification/addNotification', {event: "onTeamUpdate"});
      }).catch(() => {console.log('cannot delete user')});
    }
  },
  mounted(){
    this.$emit('set-member-sidebar',{active: "member.delete"});
  },
};
</script>
